
import { Component } from "vue-property-decorator";
import Vue from "@/interfaces/vue";
import { get } from "@/api";
import SettlementsList from "@/components/settlements/SettlementsList.vue";
import { Settlement } from "@/interfaces/therapist";
import Spinner from "@/components/Spinner.vue";

@Component({
  components: {
    SettlementsList,
    Spinner,
  },
})
export default class SettlementsView extends Vue {
  settlements!: Settlement[];
  loaded = false;
  user = this.$store.state.user?.first_name;

  mounted() {
    this.getSettlements();
  }

  async getSettlements() {
    try {
      this.fetchSettlements();
    } catch (error) {
      console.log(error);
    }
  }

  async fetchSettlements() {
    const { data } = await get.settlements();
    this.loaded = true;
    this.settlements = data;

    this.settlements.sort((settlement1, settlement2) => {
      if (settlement1 > settlement2) {
        return 0;
      } else {
        return 1;
      }
    });
  }

  settlementDateRange(startDate: number, endDate: number) {
    const startDay = new Date(startDate).getDay();
    const endDay = new Date(endDate).getDay();
    if (startDay == endDay) {
      return new Date(startDate).toLocaleDateString();
    } else {
      return (
        new Date(startDate).toLocaleDateString() +
        " - " +
        new Date(endDate).toLocaleDateString()
      );
    }
  }

  isPastDate(startDate: number) {
    const todayDate = new Date().getTime();
    return todayDate > startDate;
  }
}
