
import { Component, Prop } from "vue-property-decorator";

import Vue from "@/interfaces/vue";
import { SessionCommission } from "@/interfaces/therapist";

@Component({})
export default class SettlementsList extends Vue {
  @Prop() readonly visit!: SessionCommission;
  @Prop() readonly currency!: string;
  @Prop() readonly id!: number

  get visitDate() {
    return new Date(this.visit.date).toLocaleDateString();
  }

  get visitHour() {
    const formatter = Intl.DateTimeFormat("en-u-hc-h23", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return formatter.format(new Date(this.visit.date));
  }

  get visitType() {
    const visit_type = this.$store.state.dict?.visit_types.find((visit) => {
      return visit.id === this.visit.visit_type;
    });
    return visit_type?.name;
  }
}
