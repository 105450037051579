
import { Component, Prop } from "vue-property-decorator";
import Vue from "@/interfaces/vue";
import SettlementRow from "@/components/settlements/SettlementRow.vue";
import { SessionCommission } from "@/interfaces/therapist";

@Component({
  components: {
    SettlementRow,
  },
})
export default class SettlementsList extends Vue {
  @Prop() readonly visits!: SessionCommission[];
  @Prop() readonly currency!: string;

  get moneyEarned() {
    let moneyEarned = 0
    this.visits.forEach(visit=>{
      moneyEarned += Number(visit.price)
    })
    return moneyEarned + " " + this.currency
  }

  get calmsieCommission() {
    let commission = 0
    this.visits.forEach(visit=>{
      commission += Number(visit.commission)
    })
    return commission + " " + this.currency
  }
}
